/************* Variables *************/

:root {
  --light-primary: #fff;
  --dark-primary: #000;
  --dark-secondary: #b9b9b9;
}

@custom-media --xsmall (min-width: 370px);
@custom-media --medium (min-width: 700px);
@custom-media --medium-large (min-width: 900px);
@custom-media --large (min-width: 1300px);
@custom-media --xlarge (min-width: 1600px);

/************* Low-level *************/

html {
  font-size: 62.5%;
  @media (--xlarge) {
    font-size: 80%;
  }
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Menlo, Courier, monospace;
  color: var(--dark-primary);
}

/************* Fonts *************/

h1,
h2,
h3 {
  font-family: minion-pro, serif;
  font-weight: 600;
  font-style: normal;
  line-height: 1.1;
  margin-top: 0;
}

h1,
h2 {
  font-size: 3rem;
  @media (--xsmall) {
    font-size: 3.7rem;
  }
  @media (--medium) {
    font-size: 6rem;
  }
  letter-spacing: -1.225px;
  @media (--medium) {
    letter-spacing: -1.75px;
  }
  @media (--medium) {
    margin-bottom: 1.75rem;
  }
}

p,
a,
span,
small,
button,
input,
ul,
li {
  font-size: 1.4rem;
  @media (--xsmall) {
    font-size: 1.6rem;
  }
  @media (--medium) {
    font-size: 2rem;
  }
  line-height: 1.6;
  letter-spacing: 0.35px;
  @media (--medium) {
    letter-spacing: 0.5px;
  }
}

small {
  font-size: 1.2rem;
  @media (--xsmall) {
    font-size: 1.4rem;
  }
  @media (--xsmall) {
    font-size: 1.6rem;
  }
  color: var(--dark-secondary);
}

a {
  position: relative;
  background-color: transparent;
  color: var(--dark-primary);
  text-decoration: none;

  &::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1rem;
    border-bottom: 0.1rem dashed var(--dark-primary);
  }
}

b,
strong {
  font-weight: bolder;
}

ul {
  margin: 3rem 0;
}

li {
  margin-bottom: 1rem;
}
/************* Elements *************/

img {
  border-style: none;
}

form {
  position: relative;
  margin: 4rem 0 0.5rem;
  @media (--medium) {
    margin: 2.5rem 0 1.5rem;
  }
}

button,
input {
  box-sizing: border-box;
  font-family: inherit;
  letter-spacing: 0.35px;
  line-height: 1.1;
  overflow: visible;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: 27rem;
  @media (--xsmall) {
    max-width: 32rem;
  }
  @media (--medium) {
    max-width: none;
  }
  width: 100%;
  border-radius: 0;
  appearance: none;
}

input {
  border: 0.1rem dashed var(--dark-secondary);
  padding: 1.3rem 1.5rem;
  @media (--xsmall) {
    padding: 1.75rem 1.5rem;
  }
  color: var(--dark-primary);
  @media (--medium) {
    width: 38rem;

    &[type="submit"] {
      width: 28rem;
    }
  }
}

button {
  text-transform: none;
}

button,
[type="button"],
[type="submit"] {
  -webkit-appearance: button;
  text-transform: uppercase;
  background: var(--dark-primary);
  color: var(--light-primary);
  border: none;
  margin: 0.5rem 0;
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

::placeholder {
  color: var(--dark-secondary);
}

:invalid {
  box-shadow: none;
}

/************* Main *************/

main {
  display: block;
}

section {
  position: relative;
  text-align: center;

  & .wrap {
    max-width: 28rem;
    @media (--xsmall) {
      max-width: 34rem;
    }
    @media (--medium) {
      max-width: 70rem;
    }
    @media (--medium-large) {
      max-width: 90rem;
    }
    @media (--large) {
      max-width: 130rem;
    }
    margin: auto;
    padding: 10rem 2rem;
    @media (--xsmall) {
      padding: 14rem 2rem;
    }
    @media (--medium) {
      padding: 24rem 2rem;
    }
    @media (--medium-large) {
      padding: 24rem 8rem;
    }
  }

  & .screen {
    background: var(--dark-primary);
    border: 0.1rem dashed var(--light-primary);
    border-radius: 1.3rem;
    height: 22.5rem;
    @media (--medium) {
      height: 27.2rem;
    }
    width: 28rem;
    @media (--medium) {
      width: 34rem;
    }
    margin: 6rem auto 0;
    overflow: hidden;

    & .chat {
      padding: 2.5% 0;

      & > span {
        display: block;
        width: 75%;
        margin: 2.5% 10%;
        padding: 2% 4.5%;
        border: 0.1rem solid var(--light-primary);
        border-radius: 0.8rem;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        line-height: 1.3;
        letter-spacing: 0;
        text-align: left;

        &.user {
          border-top-right-radius: 0;
          color: var(--light-primary);
        }

        &.service {
          border-top-left-radius: 0;
          background: var(--light-primary);
          color: var(--dark-primary);
          transform: translateX(-5%);
          @media (--xsmall) {
            font-size: 1.4rem;
          }
          @media (--medium) {
            font-size: 1.7rem;
          }
        }
      }
    }
  }

  &#hero {
    border-bottom: 8rem solid var(--dark-primary);
    @media (--xsmall) {
      border-bottom: 14rem solid var(--dark-primary);
    }
    @media (--medium) {
      border-bottom: 18rem solid var(--dark-primary);
    }

    & .wrap {
      padding-top: 6rem;
      @media (--medium) {
        padding-top: 12rem;
      }
      padding-bottom: 0;
    }

    & h1 {
      @media (--medium) {
        margin-bottom: 1.5rem;
      }
    }

    & p {
      @media (--medium) {
        max-width: 54rem;
        margin: auto;
      }
    }

    & .phone-container {
      position: relative;
      overflow: hidden;
      height: 44rem;
      @media (--medium) {
        height: 56rem;
      }
    }

    & .screen {
      position: absolute;
      top: 23%;
      left: -100%;
      right: -100%;
      z-index: 2;
      margin: 0 auto;
      border: none;
      padding: 0;
      border-radius: 1rem;
      @media (--medium) {
        top: 24%;
      }
      overflow-y: hidden;

      & .chat {
        transform: translateY(22.5rem);
        @media (--medium) {
          transform: translateY(27.5rem);
        }
      }
    }

    & .phone {
      position: relative;
      width: 30.5rem;
      transform: translateX(-1%);
      z-index: 1;
      @media (--medium) {
        width: 40rem;
      }
      margin: 6rem auto 0;
      @media (--medium) {
        /* margin: 16rem auto 0; */
      }
    }
  }

  &#directions,
  &#locations,
  &#dictionary,
  &#about {
    background: var(--dark-primary);
    color: var(--light-primary);

    & .icon {
      display: block;
      height: 100%;
      max-height: 1.7rem;
      @media (--medium) {
        max-height: 2rem;
      }
      margin: 0 auto 2rem;
      @media (--medium) {
        margin: 0 auto 2.5rem;
      }
    }

    & p {
      @media (--medium) {
        max-width: 54rem;
        margin: auto;
      }
    }
  }

  &#dictionary {
    padding-bottom: 8rem;
    @media (--xsmall) {
      padding-bottom: 14rem;
    }
    @media (--medium) {
      padding-bottom: 18rem;
    }
  }

  &#signup {
    & .wrap {
      @media (--medium) {
        padding: 18rem 0;
      }
    }
    & p {
      @media (--medium) {
        max-width: 54rem;
        margin: auto;
      }
    }
  }

  &#about {
    @media (--medium) {
      padding: 12rem 0 16rem;
    }
    text-align: left;
    & p {
      @media (--medium) {
        max-width: 66rem;
        margin-left: 0;
      }
    }
  }

  &#legal {
    text-align: left;

    & h2 {
      margin-top: 8rem;
    }

    & p {
      max-width: 80rem;
    }
  }
}

/************* Header *************/

header {
  margin: auto;
  padding: 2rem 2rem 0;
  @media (--medium) {
    padding: 3rem 4rem 0;
  }
  @media (--medium-large) {
    padding: 4rem 5.5rem 0;
  }

  & .logo-link {
    text-decoration: none;
    &::after {
      display: none;
    }

    & img {
      width: 2rem;
    }
  }
}

/************* Footer *************/

footer {
  max-width: 28rem;
  @media (--xsmall) {
    max-width: 34rem;
  }
  @media (--medium) {
    max-width: 70rem;
  }
  @media (--medium-large) {
    max-width: 90rem;
  }
  @media (--large) {
    max-width: 130rem;
  }
  margin: auto;
  padding: 6rem 2rem;
  @media (--medium) {
    padding: 12rem 2rem 6rem;
  }
  @media (--large) {
    padding: 16rem 2rem 6rem;
  }

  & br {
    @media (--medium) {
      display: none;
    }
  }

  & .top {
    &::after {
      content: "";
      display: table;
      clear: both;
    }

    & .contact {
      @media (--medium) {
        float: left;
      }

      & .contact-line {
        margin: 0;

        & .heading {
          text-transform: lowercase;
          display: inline-block;
          width: 8rem;
          @media (--medium) {
            width: 10rem;
          }
          @media (--large) {
            width: 14rem;
          }
        }
      }
    }

    & .evil {
      @media (--medium) {
        float: right;
      }

      & p {
        @media (--medium) {
          margin: 0;
        }
      }
    }
  }

  & .bottom {
    position: relative;
    margin-top: 8rem;
    @media (--medium) {
      margin-top: 16rem;
    }
    @media (--large) {
      margin-top: 20rem;
    }

    &::after {
      content: "";
      display: table;
      clear: both;
    }

    & .copyright {
      @media (--medium) {
        float: left;
      }
    }

    & .legal {
      float: left;
      @media (--medium) {
        float: right;
      }

      & a:not(:last-of-type) {
        @media (--medium) {
          margin-right: 4rem;
        }
        @media (--large) {
          margin-right: 8rem;
        }
      }
    }

    & .glyph {
      display: none;
      @media (--medium) {
        position: absolute;
        width: 2rem;
        left: -110%;
        bottom: 0rem;
        right: -90%;
        display: inline-block;
        margin: 0 auto;
      }
      @media (--medium) {
        left: -100%;
        right: -100%;
      }
    }
  }
}

/************* Animations *************/
